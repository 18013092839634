<template>
  <v-card
    elevation="0"
    color="transparent"
    dark
    style="position: unset; padding-top: 142px; padding-right: 102px; padding-left: 102px;"
  >
    <h3 class="step-1-title mb-6">
      {{ $t('page.databases.add_database_title') }}
    </h3>
    <v-form
      ref="form"
      @submit.prevent
    >
      <v-row
        no-gutters
        style="max-width: 340px;"
      >
        <v-col>
          <v-text-field
            v-model.trim="name"
            class="mb-2"
            :placeholder="$t('page.databases.placeholder_database_name')"
            :rules="rulesName"
            solo
            flat
            light
          />
        </v-col>
      </v-row>
    </v-form>

    <p class="pl-4">
      {{ $t('page.databases.add_database_content') }}
    </p>

    <div class="logo-img" />
  </v-card>
</template>

<script>
import { validateRequired, validateNotEnNum, validateDuplicate } from '@/assets/js/validate'

import { mapState } from 'vuex'

const rulesName = vm => [
  validateRequired,
  validateNotEnNum,
  validateDuplicate(vm.databaseNames)
]

export default {
  name: 'DatabaseAdderStep1',

  computed: {
    ...mapState({
      databases: state => state.databases.databases,
      formData: state => state.databases.form.formData
    }),
    rulesName,

    databaseNames() {
      return this.databases.map(db => db.name)
    },

    name: {
      get() {
        return this.formData.name
      },
      set(newVal) {
        this.$store.dispatch('databases/form/setFormData', {
          name: newVal
        })
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
.step-1-title {
  font-size: 2.25rem;
  font-weight: normal;
}

.logo-img {
  position: absolute;
  right: 92px;
  bottom: 36px;
  width: 208px;
  height: 112px;
  background-size: cover;
  opacity: 0.35;
  background-image: url("../../assets/images/logo.svg");
}
</style>
