var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-9"},[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('DatabaseAdder',{on:{"successAdded":_vm.fetchDatabases},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-add-database",attrs:{"color":"primary","disabled":_vm.disableActionsBtn}},'v-btn',attr,false),on),[_vm._v(" "+_vm._s(_vm.$t('page.databases.add_database_title'))+" ")])]}}])})],1)],1),(_vm.error)?_c('IssueCard',{staticClass:"mt-13",attrs:{"message":_vm.$t('api_errors.data_error_refresh_page'),"error":""}}):_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"database-table",staticStyle:{"width":"100%"},attrs:{"headers":_vm.tableHeaders,"items":_vm.databases,"items-per-page":-1,"search":_vm.search,"item-class":function () { return 'database-tr'; },"loading":_vm.isLoadingDatabases || _vm.isDeleteing,"no-data-text":_vm.$t('page.databases.no_data_and_create_database'),"loading-text":((_vm.$t('loading')) + "..."),"fixed-header":"","border":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var database = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(database.name)+" ")]),_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"max-width":"100%"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"flex-shrink-1 text-truncate",attrs:{"cols":"auto"}},[_c('span',{staticClass:"grey--text text-body-2"},[_vm._v(_vm._s(database.resourceUuid))])]),_c('v-col',{staticClass:"ml-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","x-small":"","title":_vm.$t('copy_uuid')},on:{"click":function($event){return _vm.copy(database.resourceUuid)}}},[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1)],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
var database = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":"","color":"primary","disabled":_vm.disableActionsBtn},on:{"click":function($event){return _vm.deleteDatabase(database)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }